import { Component, inject } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { BillingInterface, UserInterface } from 'voxr-shared';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { MenuItem } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { AppService } from '../../../services/app.service';
import { BillingService } from '../../../services/billing.service';
import { combineLatest } from 'rxjs';
import { UserAvatarComponent } from "../user-avatar/user-avatar.component";

@Component({
  selector: 'app-user-status',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonModule, InputTextModule, InputSwitchModule, MenuModule, UserAvatarComponent],
  templateUrl: './user-status.component.html',
  styleUrl: './user-status.component.scss'
})
export class UserStatusComponent {
  private app = inject(AppService);
  private userService = inject(UserService);

  user?: UserInterface & { _impersonated?: boolean } | null;
  billing?: BillingInterface;
  name: string = ' ';
  loading = true;

  menuItems: MenuItem[] = [];

  constructor() {
    this.userService.user$.pipe(takeUntilDestroyed()).subscribe(async user => await this.load(user));
  }

  private load(user?: UserInterface) {
    this.user = user;
    this.billing = this.user?._metadata?.billing as BillingInterface;
    if (this.user) {
      this.name = (this.user.profile?.['firstName'] || this.user.profile?.['name'] || this.user.email || '') +
        (this.user.profile?.['lastName'] ? ' ' + this.user.profile['lastName'] : '');

      this.menuItems = [{ label: 'My Profile', icon: 'pi pi-user', routerLink: '/profile' },]

      if (!!this.billing?.customerId)
        this.menuItems.push({ label: 'Account Settings', icon: 'pi pi-cog', routerLink: '/account' });
      if (!!this.user?.resellerConfig?.isApproved)
        this.menuItems.push({ label: 'Reseller Settings', icon: 'pi pi-cog', routerLink: '/reseller' });
      if (this.user.roles?.includes('super-admin') || this.user.roles?.includes('operator'))
        this.menuItems.push({ label: 'Admin Area', icon: 'pi pi-shield', routerLink: '/admin' });

      this.menuItems.push({ label: 'Logout', icon: 'pi pi-sign-out', command: () => this.app.logout() });
    }
    this.loading = false;
  }

  unimpersonate() { this.userService.unImpersonate(); }
}
