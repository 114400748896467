@if(user) {
<div class="tw-flex tw-items-center tw-gap-4" [ngClass]="{'tw-animate-pulse': loading}">
  <div pRipple class="tw-flex tw-gap-2 tw-p-1 tw-cursor-pointer hover:surface-main" (click)="statusPanel.toggle($event)"
    class="tw-cursor-pointer hover:surface-main">
    @if(user.agentInfo!.liveTransfer!.available) {
    <i class="pi pi-headphones text-success tw-text-lg tw-font-bold"></i> Available
    ({{user.agentInfo!.liveTransfer!.phone}})
    } @else {

    <i class="pi pi-headphones text-danger tw-text-lg tw-font-bold"></i> Not available for live transfer
    }
  </div>
  @if(socketConnected) {
  <i class="pi pi-wifi text-success" matTooltip="Connected to live transfer server."></i>
  } @else {
  <div class="uw-tag uw-tag-warning" class="tw-animate-pulse">Connecting...</div>
  }
  @if(!!incomingTransfer) {
  <p-button label="Incoming Transfer" icon="pi pi-phone" size="small" severity="warning" class="tw-animate-pulse"
    (click)="transferPanel.toggle($event)"></p-button>
  <p-overlayPanel #transferPanel [dismissable]="false">
    <div class="tw-flex tw-flex-col tw-gap-4 tw-max-w-prose">
      @if(incomingTransfer.campaign) {
      <h1>{{incomingTransfer.campaign.name}}</h1>
      }
      @if(incomingTransfer.call; as call) {
      <div class="tw-flex tw-gap-2">
        <div class="uw-tag uw-tag-info">{{call.flow}}</div>
        <div>
          <i class="pi pi-phone"></i>
          @switch(call.flow) {
          @case ('INBOUND') { {{call.fromNumber}} }
          @case ('OUTBOUND') { {{call.toNumber}} }
          }
        </div>
      </div>
      @if(call.contact; as contact) {
      <div class="tw-flex tw-items-center tw-gap-4">
        <i class="pi pi-user"></i> {{contact.firstName}} {{contact.lastName}}
        @if(contact.crmLink){<a pButton class="tw-mx-2" [href]="contact.crmLink" target="_blank" [text]="true"
          matTooltip="Show in CRM"><i class="pi pi-external-link tw-me-2"></i> CRM</a>}
      </div>
      } @else {
      Contact Id: {{call.contactId}}
      }
      @if(call.summary) {
      <h6>Call Summary</h6>
      <div class="tw-whitespace-pre-wrap surface-main-darker tw-p-2 tw-rounded">{{call.summary}}</div>
      }
      }
      <div class="tw-flex tw-gap-6">
        <p-button label="Close" [outlined]="true" (click)="incomingTransfer= undefined"></p-button>
        <p-button label="Reject Transfer" severity="danger" (click)="rejectTransfer(transferPanel)"
          [loading]="rejecting"></p-button>
        <div>

        </div>

      </div>
    </div>
  </p-overlayPanel>
  }

</div>

<p-overlayPanel #statusPanel appendTo="body">
  <form #transferForm="ngForm" class="uw-fields-container" [ngClass]="{'tw-animate-pulse': loading}">
    <div class="tw-flex tw-items-center tw-gap-2">
      <p-inputSwitch name="available" id="available" (onChange)="setAvailability()"
        [(ngModel)]="user.agentInfo!.liveTransfer!.available"></p-inputSwitch>
      <label for="available">Available for live transfer</label>
    </div>
    @if(user.agentInfo!.liveTransfer!.available) {
    <div class="p-fluid p-float-label">
      <input pInputText id="phone" name="phone" [(ngModel)]="user.agentInfo!.liveTransfer!.phone"
        (change)="setAvailability()" />
      <label for="phone">Transfer Destination</label>
    </div>

    }
    <!-- <div class="uw-sticky-bottom-toolbar">
      <p-button label="Set Transfer Availability" severity="primary" (click)="setAvailability()" [loading]="loading"
        [disabled]="transferForm.invalid"></p-button>
    </div> -->

  </form>
</p-overlayPanel>

}