import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { WorkspaceService } from '../services/workspace.service';
import { Auth } from '@angular/fire/auth';
import { from, switchMap } from 'rxjs';
import { UserService } from '../services/user.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(Auth);
  const userService = inject(UserService);
  const workspaceService = inject(WorkspaceService);

  var headers: { [name: string]: string | string[] } = {};

  if (auth.currentUser) {
    return from(auth.currentUser!.getIdToken()).pipe(
      switchMap(idToken => {
        if (idToken) headers['Authorization'] = `Bearer ${idToken}`;
        if (userService.impersonated()) headers['x-voxr-impersonate'] = userService.impersonated()!;
        if (workspaceService.workspaceId) headers['x-voxr-workspace'] = workspaceService.workspaceId;

        req = req.clone({ setHeaders: headers });

        return next(req);
      }
      ));
  }
  else {
    return next(req);
  }

};
