<div class="uw-layout tw-flex-col">
  <header class="uw-layout-header uw-focus-header">
    <a class="uw-nav-logo" routerLink="/">
      <img  [src]="logoUrl" [alt]="brand?.name || 'VOXR'" />
    </a>
    <app-user-status />
  </header>
  <div class="uw-layout-content">
    <router-outlet/>
  </div>
</div>

