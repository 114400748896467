@if(!loading){
@if(user) {
<div class="tw-flex tw-gap-2 tw-items-center">
  @if(user._impersonated){<p-button class="tw-cursor-pointer" label="Exit Impersonating" severity="warning" size="small"
    icon="pi pi-key" (click)="unimpersonate()"></p-button>}
  <div class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer" (click)="userMenu.toggle($event)">
    <app-user-avatar [user]="user" />
  </div>
  <p-menu #userMenu appendTo="body" [model]="menuItems" [popup]="true"></p-menu>
</div>
} @else {
<a pButton label="Login or Signup" severity="primary" routerLink="/login"></a>
}
}