import { Routes } from '@angular/router';
import { SidebarLayoutComponent } from './layouts/sidebar-layout/sidebar-layout.component';
import { FocusLayoutComponent } from './layouts/focus-layout/focus-layout.component';
import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { workspaceGuard } from './guards/workspace.guard';
import { HomeComponent } from './components/general/home/home.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(`/login?redirect=${window.location.pathname}`);

export const routes: Routes = [
  {
    path: '', component: FocusLayoutComponent, children: [
      //these paths don't require authentication
      { path: '', component: HomeComponent, data: { title: 'Home' } },
      // { path: 'agreement', loadComponent: () => import('./components/general/agreement/agreement.component').then(m => m.AgreementComponent), data: { title: 'Agreement' } },
      { path: 'tos', loadComponent: () => import('./components/general/terms-of-service/terms-of-service.component').then(m => m.TermsOfServiceComponent), data: { title: 'Terms of Service' } },
      { path: 'privacy', loadComponent: () => import('./components/general/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent), data: { title: 'Privacy Policy' } },
      { path: 'join/:workspaceId/:invitationId', loadComponent: () => import('./components/workspace/accept-invitation/accept-invitation.component').then(m => m.AcceptInvitationComponent), data: { title: 'Invitation' } },
    ]
  },

  { path: 'login', loadComponent: () => import('./components/general/login/login.component').then(m => m.LoginComponent), data: { title: 'Login' } },
  {
    path: '', canActivate: [AuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, children: [
      {
        path: '', component: FocusLayoutComponent, children: [
          { path: 'profile', loadComponent: () => import('./components/user/user-profile/user-profile.component').then(m => m.UserProfileComponent), data: { title: 'Profile' } },
          { path: 'select-workspace', loadComponent: () => import('./components/workspace/select-workspace/select-workspace.component').then(m => m.SelectWorkspaceComponent), data: { title: 'Workspaces' } },
        ]
      },
      {
        path: 'account', title: 'Account Settings', data: { area: 'account' }, children: [
          {
            path: '', component: SidebarLayoutComponent, children: [
              { path: '', redirectTo: 'status', pathMatch: 'full' },
              { path: 'status', title: 'Account Status', loadComponent: () => import('./components/account/account-status/account-status.component').then(m => m.AccountStatusComponent), data: { title: 'Status' } },
              { path: 'phone', title: 'Account Phone Settings', loadComponent: () => import('./components/account/account-phone-settings/account-phone-settings.component').then(m => m.AccountPhoneSettingsComponent), data: { title: 'Phone Settings' } },
              { path: 'workspaces', title: 'Workspaces', loadComponent: () => import('./components/account/account-workspaces/account-workspaces.component').then(m => m.AccountWorkspacesComponent), data: { title: 'Workspaces' } },
            ]
          },
          {
            path: '', component: FocusLayoutComponent, children: [
              { path: 'onboarding', title: 'Account Onboarding', loadComponent: () => import('./components/account/account-onboarding/account-onboarding.component').then(m => m.AccountOnboardingComponent), data: { title: 'Onboarding' } },
            ]
          },
        ]
      },
      {
        path: 'reseller', title: 'Reseller Settings', data: { area: 'reseller' }, children: [
          {
            path: '', component: SidebarLayoutComponent, children: [
              { path: '', redirectTo: 'status', pathMatch: 'full' },
              { path: 'onboarding', loadComponent: () => import('./components/reseller/reseller-onboarding/reseller-onboarding.component').then(m => m.ResellerOnboardingComponent), data: { title: 'Onboarding' } },
              { path: 'status', title: 'Reseller Status', loadComponent: () => import('./components/reseller/reseller-status/reseller-status.component').then(m => m.ResellerStatusComponent), data: { title: 'Status' } },
              { path: 'whitelabel', title: 'White-Label Settings', loadComponent: () => import('./components/reseller/reseller-whitelabel/reseller-whitelabel.component').then(m => m.ResellerWhitelabelComponent), data: { title: 'White-Label Settings' } },
              // { path: 'plans', title: 'Manage Reseller Plans', loadComponent: () => import('./components/reseller/reseller-manage-plans/reseller-manage-plans.component').then(m => m.ResellerManagePlansComponent), data: { title: 'Manage Reseller Plans' } },
              { path: 'plans', title: 'Manage Plans', loadComponent: () => import('./components/admin/manage-plans/manage-plans.component').then(m => m.ManagePlansComponent), data: { title: 'Plans' } },
              { path: 'users', title: 'Manage Users', loadComponent: () => import('./components/admin/manage-users/manage-users.component').then(m => m.ManageUsersComponent), data: { title: 'Users' } },
              { path: 'blueprints', title: 'Default Blueprints', loadComponent: () => import('./components/admin/default-blueprints/default-blueprints.component').then(m => m.DefaultBlueprintsComponent), data: { title: 'Default Blueprints' } },
              { path: 'campaign-templates', title: 'Campaign Templates', loadComponent: () => import('./components/admin/campaign-templates/campaign-templates.component').then(m => m.CampaignTemplatesComponent), data: { title: 'Campaign Templates' } },
              { path: 'phone', title: 'Phone Settings', loadComponent: () => import('./components/account/account-phone-settings/account-phone-settings.component').then(m => m.AccountPhoneSettingsComponent), data: { title: 'Phone Settings' } },
              { path: 'workspaces', title: 'Workspaces', loadComponent: () => import('./components/account/account-workspaces/account-workspaces.component').then(m => m.AccountWorkspacesComponent), data: { title: 'Workspaces' } },
            ]
          },
          {
            path: '', component: FocusLayoutComponent, children: [
              { path: 'onboarding', title: 'Reseller Onboarding', loadComponent: () => import('./components/reseller/reseller-onboarding/reseller-onboarding.component').then(m => m.ResellerOnboardingComponent), data: { title: 'Onboarding' } },
            ]
          },

        ]
      },
      {
        path: 'admin', title: 'Admin Area', component: SidebarLayoutComponent, data: { area: 'admin' }, children: [
          { path: '', redirectTo: 'status', pathMatch: 'full' },
          { path: 'status', title: 'VOXR Status', loadComponent: () => import('./components/admin/system-status/system-status.component').then(m => m.SystemStatusComponent), data: { title: 'Status' } },
          { path: 'users', title: 'Manage Users', loadComponent: () => import('./components/admin/manage-users/manage-users.component').then(m => m.ManageUsersComponent), data: { title: 'Users' } },
          { path: 'workspaces', title: 'Manage Workspaces', loadComponent: () => import('./components/admin/manage-workspaces/manage-workspaces.component').then(m => m.ManageWorkspacesComponent), data: { title: 'Workspaces' } },
          { path: 'campaigns', title: 'Manage Campaigns', loadComponent: () => import('./components/admin/admin-manage-campaigns/admin-manage-campaigns.component').then(m => m.AdminManageCampaignsComponent), data: { title: 'Campaigns' } },
          { path: 'reports', title: 'Reports', loadComponent: () => import('./components/admin/admin-reports/admin-reports.component').then(m => m.AdminReportsComponent), data: { title: 'Reports' } },
          { path: 'calls', title: 'Call Logs', loadComponent: () => import('./components/admin/admin-manage-calls/admin-manage-calls.component').then(m => m.AdminManageCallsComponent), data: { title: 'Call Logs' } },
          { path: 'integrations', title: 'Default Integrations', loadComponent: () => import('./components/admin/default-integrations/default-integrations.component').then(m => m.DefaultIntegrationsComponent), data: { title: 'Default Integrations' } },
          { path: 'plans', title: 'Manage Plans', loadComponent: () => import('./components/admin/manage-plans/manage-plans.component').then(m => m.ManagePlansComponent), data: { title: 'Plans' } },
          { path: 'blueprints', title: 'Default Blueprints', loadComponent: () => import('./components/admin/default-blueprints/default-blueprints.component').then(m => m.DefaultBlueprintsComponent), data: { title: 'Default Blueprints' } },
          { path: 'campaign-templates', title: 'Campaign Templates', loadComponent: () => import('./components/admin/campaign-templates/campaign-templates.component').then(m => m.CampaignTemplatesComponent), data: { title: 'Campaign Templates' } },
          { path: 'vapi', title: 'Manage Vapi', loadComponent: () => import('./components/admin/manage-vapi/manage-vapi.component').then(m => m.ManageVapiComponent), data: { title: 'Vapi' } },
          { path: 'logs', title: 'System Logs', loadComponent: () => import('./components/admin/system-logs/system-logs.component').then(m => m.SystemLogsComponent), data: { title: 'System Logs' } },
        ]
      },
      {
        path: 'w/:workspace', canActivate: [workspaceGuard], data: { area: 'workspace' }, children: [
          {
            path: '', component: SidebarLayoutComponent, children: [
              // { path: '',  redirectTo: 'assistants', pathMatch: 'full' },
              { path: 'dashboard', loadComponent: () => import('./components/general/dashboard/dashboard.component').then(m => m.DashboardComponent), data: { title: 'Dashboard' } },
              {
                path: 'assistants', children: [
                  { path: '', loadComponent: () => import('./components/assistant/manage-assistants/manage-assistants.component').then(m => m.ManageAssistantsComponent), data: { title: 'Assistants' } },
                  { path: ':assistantId', loadComponent: () => import('./components/assistant/assistant-detail/assistant-detail.component').then(m => m.AssistantDetailComponent), data: { title: 'Assistant' } },

                ]
              },
              // { path: 'contacts', loadComponent: () => import('./components/contact/manage-contacts/manage-contacts.component').then(m => m.ManageContactsComponent), data: { title: 'Contacts' } },
              {
                path: 'campaigns',
                children: [
                  { path: '', loadComponent: () => import('./components/campaign/manage-campaigns/manage-campaigns.component').then(m => m.ManageCampaignsComponent), data: { title: 'Campaigns' } },
                  { path: ':campaignId', loadComponent: () => import('./components/campaign/campaign-detail/campaign-detail.component').then(m => m.CampaignDetailComponent), data: { title: 'Campaign Edit' } }
                ]
              },
              { path: 'calls', loadComponent: () => import('./components/call/calls-list/calls-list.component').then(m => m.CallsListComponent), data: { title: 'Call Logs' } },
              { path: 'reports', loadComponent: () => import('./components/workspace/workspace-reports/workspace-reports.component').then(m => m.WorkspaceReportsComponent), data: { title: 'Reports' } },
              { path: 'blueprints', loadComponent: () => import('./components/blueprint/manage-blueprints/manage-blueprints.component').then(m => m.ManageBlueprintsComponent), data: { title: 'Blueprints' } },
              { path: 'integration', loadComponent: () => import('./components/integration/integrations/integrations.component').then(m => m.IntegrationsComponent), data: { title: 'Integrations' } },
              { path: 'members', loadComponent: () => import('./components/workspace/members/members.component').then(m => m.MembersComponent), data: { title: 'Members' } },
              { path: 'settings', loadComponent: () => import('./components/workspace/settings/settings.component').then(m => m.SettingsComponent), data: { title: 'Settings' } },
            ]
          },
          {
            path: '', component: FocusLayoutComponent, children: [
              { path: 'onboarding', loadComponent: () => import('./components/general/onboarding/onboarding.component').then(m => m.OnboardingComponent), data: { title: 'Onboarding' } },
            ]
          },
        ]
      },
    ]
  },
];
