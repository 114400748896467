import { Component, inject } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { AppService } from '../../../services/app.service';
import { incomingCallPayload, UserInterface } from 'voxr-shared';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RippleModule } from 'primeng/ripple';
import { CallService } from '../../../services/call.service';
import { SocketService } from '../../../services/socket.service';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-transfer-availability',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonModule, OverlayPanelModule, InputTextModule, InputSwitchModule, RippleModule],
  templateUrl: './transfer-availability.component.html',
  styleUrl: './transfer-availability.component.scss'
})
export class TransferAvailabilityComponent {
  private userService = inject(UserService);
  private app = inject(AppService);
  private callService = inject(CallService);
  private socketService = inject(SocketService);

  user?: UserInterface | null;
  name: string = ' ';
  loading: boolean = false;
  incomingTransfer?: incomingCallPayload;
  rejecting: boolean = false;
  socketConnected = false;

  constructor() {
    this.userService.user$.pipe(takeUntilDestroyed()).subscribe(user => this.loadUser(user));
    this.callService.incomingCall$.pipe(takeUntilDestroyed()).subscribe(async payload => await this.onIncomingCall(payload));
    this.socketService.connected$.pipe(takeUntilDestroyed(), debounceTime(1000)).subscribe(connected => this.socketConnected = connected);
  }

  async onIncomingCall(payload: incomingCallPayload) {
    switch (payload.type) {
      case 'transfer':
        this.incomingTransfer = payload;
        break;
    }
  }

  async rejectTransfer(panel: OverlayPanel) {
    try{
      this.rejecting = true;
      await this.callService.rejectTransfer(this.incomingTransfer!.call!._id!.toString(), this.incomingTransfer!.conferenceName!);
      this.app.message$.next({ severity: 'info', detail: 'Transfer rejected.' });
    } finally {
      this.rejecting = false;
      panel.hide();
      this.incomingTransfer = undefined;
    } 
  }

  private loadUser(user?: UserInterface | null) {
    this.user = user;
    if (this.user) {
      if (!this.user.agentInfo) this.user.agentInfo = {};
      if (!this.user.agentInfo.liveTransfer) this.user.agentInfo.liveTransfer = { available: false, phone: this.user.phone };
    }

  }

  async setAvailability() {
    this.loading = true;
    await this.userService.setTransferAvailability(this.user!.agentInfo!.liveTransfer!);
    this.app.message$.next({ severity: 'success', summary: 'Success', detail: 'Updated live transfer availability.' });
    this.loading = false;

  }

}
